import { BoxProps, Box as MuiBox } from "@mui/system";
import { NO_TRANSLATE_CLASS } from "../../types/localize-types";

type NoTranslateProps = Pick<BoxProps, "sx" | "children" | "component">;

/**
 * @returns A component whose children will not be translated by localizeJS
 */
export function NoTranslate({
  children,
  sx,
  component = "div",
}: NoTranslateProps): JSX.Element {
  return (
    <MuiBox component={component} sx={sx} className={NO_TRANSLATE_CLASS}>
      {children}
    </MuiBox>
  );
}
